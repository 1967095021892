<template>
    <div>
        <div v-for="(value, key) in data.checklists" :key="key">
            <div class="d-flex align-items-center hover-wrapper" flex-width="100%">
                <span class="p-2">
                    <OContentEditable class="form-control-plaintext border-0 py-0 m-0 text-muted fw-bold ps-4" type="text"
                        autocomplete="off" v-model="value.name" :id="'checklist_' + key" />
                </span>
                <div class="btn-group show-on-hover" role="group">
                    <button class="btn ms-2 p-0 border-0" @click="deleteChecklist(key)"><i
                            class="bi bi-x-lg"></i></button>
                </div>
                <div class="divider mx-3"></div>
            </div>
            <ChecklistItems :checklist="value" :index="key" />
        </div>
    </div>
</template>
    
<script setup>
import ChecklistItems from 'o365.libraries.vue.components.Checklists.ChecklistItems2.vue'
import { confirm } from 'o365-vue-services';

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
})

function deleteChecklist(key) {
    const options = {
        message: $t('Are you sure you want to delete this Item?'),
        title: $t('Delete confirm'),
        btnTextOk: 'Delete',
        btnClassOk: 'btn-danger',
        zIndex: 1060
    }

    confirm(options).then(() => {
        props.data.checklists.splice(key, 1)
    }).catch(_ => { })
}

window.checklists = props.data.checklists

</script>

